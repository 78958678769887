import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { Typography, Breadcrumb, Tabs, Badge } from 'antd';
import { Div, Button } from '../../../framework';
import { RootState } from '../../../store/store';
import ProjectTabContent from './ProjectTabContent';
import { setFlyoutOpen, setMenuMode } from '../../../store/app.slice';
import { useResetScroll } from '../../../helper/hook';
import { Project, RequestPaymentInvoiceStatus } from '../../../helper/type';
import * as analyticService from '../../../service/analytic.service';
import { useJobService } from '../../../service/job.service';
import {
  setProjects,
  setRequestPaymentInvoices,
} from '../../../store/hirer.slice';
import ToRateTabContent from './ToRateTabContent';
import { featureToggle } from '../../../../config/app.config';
import { useAccountingService } from '../../../service/accounting.service';

import './Projects.scss';

const { Title } = Typography;
const { TabPane } = Tabs;

const ProjectsPage = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const jobService = useJobService();
  const accountingService = useAccountingService();
  const [tab, setTab] = useState('current');
  const { loadStack } = useSelector((state: RootState) => state.app);
  const { authHirer } = useSelector((state: RootState) => state.user);
  const { mappedProjects, pendingRatings, mappedRequestPaymentInvoice } =
    useSelector((state: RootState) => state.hirer);

  const activeProjects = Object.values(mappedProjects).filter(
    (project) => project.closingDate > new Date().toISOString()
  );
  const expiredProjects = Object.values(mappedProjects).filter(
    (project) => project.closingDate < new Date().toISOString()
  );
  const unpaidProjects = expiredProjects.filter(
    (project) =>
      mappedRequestPaymentInvoice[project.id] &&
      mappedRequestPaymentInvoice[project.id].status !==
        RequestPaymentInvoiceStatus.PAYMENT_RECEIVED
  );

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    analyticService.trackPage('hirer projects');
  }, []);

  useEffect(() => {
    if (loadStack.includes('job/project/list')) return;

    (async () => {
      if (authHirer.hirerId) {
        const projects: Project[] = await jobService.getProjectsByHirer(
          authHirer.hirerId
        );
        dispatch(setProjects(projects));
      }
    })();
  }, []);

  useEffect(() => {
    const setToTab = new URLSearchParams(search).get('tab');
    if (setToTab) setTab(setToTab);
  }, [search]);

  useEffect(() => {
    if (!featureToggle.invoice) return;

    (async () => {
      if (authHirer.hirerId) {
        const { data } =
          await accountingService.listRequestPaymentInvoicesByPayerId(
            authHirer.hirerId
          );

        dispatch(setRequestPaymentInvoices(data));
      }
    })();
  }, []);

  return (
    <Div className='page-hirer-projects'>
      <Helmet>
        <title>Dashboard - HeyCast.Me</title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item>All Projects</Breadcrumb.Item>
      </Breadcrumb>

      <Div flex className='title-container'>
        <Title level={4}>My Projects</Title>
        <Div flex className='button-container'>
          <Button style={{ flex: 'unset' }} to='/hirer/project'>
            + New project
          </Button>
        </Div>
      </Div>
      <Tabs activeKey={tab} onChange={setTab} centered>
        <TabPane tab='Current' key='current'>
          <ProjectTabContent projects={activeProjects} type='current' />
        </TabPane>
        {featureToggle.rating && (
          <TabPane
            tab={
              <Div flex>
                To Rate
                <Badge
                  count={pendingRatings.length}
                  overflowCount={99}
                  style={{ marginLeft: '0.25rem' }}
                />
              </Div>
            }
            key='rate'
          >
            <Div style={{ textAlign: 'center', margin: '16px 0 32px 0' }}>
              Every rating helps enhance your overall experience on HeyCast.me
            </Div>
            <ToRateTabContent />
          </TabPane>
        )}
        {featureToggle.invoice && (
          <TabPane
            tab={
              <Div flex>
                Unpaid
                <Badge
                  count={unpaidProjects.length}
                  overflowCount={99}
                  style={{ marginLeft: '0.25rem' }}
                />
              </Div>
            }
            key='unpaid'
          >
            <ProjectTabContent projects={unpaidProjects} type='unpaid' />
          </TabPane>
        )}
        <TabPane tab='Past' key='past'>
          <ProjectTabContent projects={expiredProjects} type='past' />
        </TabPane>
      </Tabs>
    </Div>
  );
};

export default ProjectsPage;
